import React, { useState, useEffect } from "react"
import { Button, CircularProgress, Switch, TextField } from "@material-ui/core"
import { globalStyles } from "../../../../Componentes/CssGlobal"
import { useStyles } from "./style"
import Topo from "../../../../Componentes/TopCategorias"
import api from "../../../../Services/api"
import CustomAlert from "../../../../Componentes/Alerts/CustomAlert"
import { useHistory } from "react-router-dom"

export default function FacebookPixel() {
  const classesGlobal = globalStyles()
  const classes = useStyles()

  const [switchValue, setSwitchValue] = useState(1)
  const [text, setText] = useState()
  const [loading, setLoading] = useState(true)
  // const [alertType, setAlertType] = useState("")
  // const [showAlertOk, setShowAlertOk] = useState(false)
  // const [showAlertError, setShowAlertError] = useState(false)
  const [alertType, setAlertType] = useState("success")
  const [showModalAlert, setShowModalAlert] = useState(false)
  const [alertMessage, setAlertMessage] = useState("")
  const [saving, setSaving] = useState(false)
  const [errorInputText, setErrorInputText] = useState("")

  const history = useHistory()

  function getData() {
    const lojinha_id = localStorage.getItem("id")
    const lojinha_token = localStorage.getItem("token")
    api.get(`/configuration/show/${lojinha_id}`).then(res => {
      setText(res.data.data[0].facebook_pixel)
      setSwitchValue(res.data.data[0].facebook_check)
      setLoading(false)
    })
  }

  // const closeAlerts = () => {
  //   setShowAlertOk(false)
  //   setShowAlertError(false)
  // }

  function handleChangeFacebookPixelInput(event) {
    const { value } = event.target
    const isNotANumber = isNaN(value)
    if (isNotANumber) {
      setErrorInputText("Digite apenas números")
    } else {
      setErrorInputText("")
    }
    setText(event.target.value)
  }

  function activeSwitch() {
    if (switchValue === 0) {
      setSwitchValue(1)
    } else {
      setSwitchValue(0)
    }
  }

  const body = {
    facebook_pixel: switchValue === 0 ? "" : text,
    facebook_check: switchValue
  }

  function dataSave() {
    if (errorInputText !== "") return
    setSaving(true)

    if (body.facebook_pixel === "") {
      setAlertMessage("Campo vazio!")
      setAlertType("error")
      setShowModalAlert(true)
      return
    }


    const id = localStorage.getItem("config_id")
    api
      .put(`/configuration/update/${id}`, body)
      .then(res => {
        setAlertMessage("Dados salvos com sucesso!")
        setAlertType("success")
        setShowModalAlert(true)
        setTimeout(() => {
          history.goBack()
        }, 1500)
      })
      .catch(error => {
        setSaving(false)
        setAlertMessage("Algo deu errado!")
        setAlertType("error")
        setShowModalAlert(true)
      })
  }

  useEffect(() => {
    getData()
  }, [])

  return (
    <>
      {loading ? (
        <div
          style={{
            backgroundColor: " #fff",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            height: "100%"
          }}
        >
          <CircularProgress color="secondary" size="40px" />
        </div>
      ) : (
        <>
          <div className="page-container">
            <div className="page-align">
              <div>
                <Topo name="Facebook Pixel" />
              </div>
              <div className="infinite-scroll">
                <div className={classes.Box}>
                  <div className={classes.titleText}>Habilitar Facebook Pixel</div>
                  <div className={classes.switchChange}>
                    <Switch
                      checked={switchValue}
                      onClick={() => {
                        activeSwitch()
                      }}
                    />
                  </div>
                </div>

                {switchValue === 1 ? (
                  <div>
                    <div className={classes.fonteTitulo}>ID do Facebook Pixel</div>
                    <div>
                      <TextField
                        style={{ backgroundColor: "#fff" }}
                        name="idFace"
                        error=""
                        helperText=""
                        value={text}
                        onChange={e => handleChangeFacebookPixelInput(e)}
                        size="small"
                        variant="outlined"
                        color="secondary"
                        fullWidth
                        inputProps={{ maxLength: 20 }}
                      />
                      <span className={classes.errorSpan}>{errorInputText}</span>
                    </div>
                    <div className={classes.Box}>
                      <h3>
                        Ative a opção do Facebook Pixel em sua loja. Informe aqui o
                        seu ID de verificação:
                      </h3>
                    </div>
                    <div className={classes.hyperlink}>
                      <a
                        className={classes.link}
                        href="https://blog.uppyapp.com.br/2021/11/como-instalar-o-pixel-do-facebook-em-sua-loja-online-uppy/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Clique aqui para acessar nosso tutorial
                      </a>
                    </div>
                  </div>
                ) : (
                  ""
                )}

                {/* <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center"
                  }}
                >
                  <CustomAlert
                    type={alertType}
                    alertText={
                      alertType === "success"
                        ? "Dados salvos com sucesso!"
                        : "Algo deu errado!"
                    }
                    show={showAlertOk || showAlertError}
                    close={() => closeAlerts()}
                  />
                </div> */}


                {/* ALERT COMPONENT */}
                {showModalAlert ? (
                  <CustomAlert
                    type={alertType}
                    alertText={alertMessage}
                    show={showModalAlert}
                    close={() => setShowModalAlert(false)}
                  />
                ) : (
                  ""
                )}
              </div>

              <div className="footer-column">
                <hr style={{ width: "100%" }} />
                <Button
                  className={classes.btnSalvar}
                  variant="contained"
                  disabled={saving}
                  onClick={() => {
                    dataSave()
                  }}
                >
                  Salvar alterações
                </Button>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  )
}
