import { Button, Dialog, Tab, Tabs } from "@material-ui/core"
import { useEffect, useRef, useState } from "react"
import { BsBoxSeam, BsCamera, BsFileEarmarkText } from "react-icons/bs"
import {
  MdOutlineDesignServices,
  MdOutlineDiscount,
  MdPlaylistAdd
} from "react-icons/md"
import { RiRulerLine } from "react-icons/ri"
import { useHistory } from "react-router-dom"
import CustomAlert from "../../Componentes/Alerts/CustomAlert"
import TopProdutos from "../../Componentes/TopProdutos"
import { useProduct } from "../../ContextAPI/productContext"
import produtosRequest from "../../Services/api/produtos"
import Dimensoes from "./Abas/Dimensoes"
import FichaTecnica from "./Abas/FichaTecnica"
import ProdutoEstoque from "./Abas/ProdutoEstoque"
import ProdutoFotos from "./Abas/ProdutoFotos"
import ProdutoInformacoes from "./Abas/ProdutoInformacoes"
import ProdutosRelacionados from "./Abas/ProdutosRelacionados"
import ProdutoVariacoes from "./Abas/ProdutosVariacoes"
import ModalExcluirProduto from "./ModalExcluirProduto"
import { useStyles } from "./style"

export default function Produto(props) {
  const { id, tabNumberProps } = (props.location && props.location) || {}
  const {
    currentProduct,
    isEdit,
    setIsEdit,
    setCurrentProduct,
    tabNumber,
    setTabNumber,
    setIsLoading,
    setCategoriesListSelected
  } = useProduct()
  const history = useHistory()
  const classes = useStyles()
  const [loading, setLoading] = useState(false)
  const [leaving, setLeaving] = useState(false)
  const [openModalDelete, setOpenModalDelete] = useState(false)
  const [emptyRequired, setEmptyRequired] = useState({
    title: 0,
    price: 0,
    images: 0
  })
  /* ALERT STATES */
  const [alertType, setAlertType] = useState("success")
  const [showModalAlert, setShowModalAlert] = useState(false)
  const [alertMessage, setAlertMessage] = useState("")
  const listInnerRef = useRef()
  const [isScroll, setIsScroll] = useState(true)

  const [leavingNoSave, setLeavingNoSave] = useState(false)
  /* ON LOAD VERIFY IF IS EDIT */
  useEffect(() => {
    if (isEdit === true) {
      handleEditProduct()
    } else {
      setTabNumber(1)
    }
  }, [isEdit])

  useEffect(() => {
    setIsScroll(true)
  }, [tabNumber])

  /* DELETE PRODUCT CALLBACK */
  function handleDelete() {
    setOpenModalDelete(true)
  }

  /* CLOSE DELETE MODAL */
  function handleCloseModal(deleted) {
    setOpenModalDelete(false)
    if (deleted) {
      history.goBack()
      setTabNumber(1)
    }
  }


  /* ON CREATE LOAD CONTEXT PRODUCT */
  useEffect(() => {
    if (currentProduct === null) {
      setCurrentProduct({
        title: "",
        description: "",
        product_banner: "",
        price: "",
        promotional_price: "",
        quantity: null,
        highlight: 0,
        top_sale: 0,
        show_product: 1,
        variation_type_selected: [],
        sku: "",
        codebar: "",
        dimension: {
          height: null,
          width: null,
          length: null,
          weight: null
        },
        category: [],
        images: [],
        product_datasheet: "",
        product_related: []
      })
    }
  }, [currentProduct])

  const checkDimesions = () => {
    return (
      !!currentProduct.dimension.height &&
      !!currentProduct.dimension.width &&
      !!currentProduct.dimension.length &&
      !!currentProduct.dimension.weight
    )
  }

  /* CREATE AND EDIT PRODUCT */
  async function saveProduct() {
    let isEmpty = checkRequiredInputs()
    if (isEmpty) return
    if (
      localStorage.getItem("active_checkout") === "payhow" &&
      checkDimesions() === false
    ) {
      setLeavingNoSave(true)
      return
    }
    setLoading(true)
    const body = {
      product_id: currentProduct.id,
      title: currentProduct.title,
      description: currentProduct.description ? currentProduct.description : null,
      product_banner: currentProduct.product_banner,
      price: Number(currentProduct.price),
      promotional_price:
        currentProduct.promotional_price !== undefined &&
          currentProduct.promotional_price !== null &&
          String(currentProduct.promotional_price).length
          ? Number(currentProduct.promotional_price)
          : null,
      quantity: currentProduct.quantity,
      highlight:
        currentProduct.highlight === true || currentProduct.highlight === 1 ? 1 : 0,
      top_sale:
        currentProduct.top_sale === true || currentProduct.top_sale === 1 ? 1 : 0,
      categories: currentProduct.categories ? currentProduct.categories : [],
      show_product:
        currentProduct.show_product === true || currentProduct.show_product === 1
          ? 1
          : 0,
      images: orderImages(),
      variation_type_selected: currentProduct.variation_type_selected
        ? currentProduct.variation_type_selected
        : [],
      sku: currentProduct.sku ? currentProduct.sku : null,
      codebar: currentProduct.codebar ? currentProduct.codebar : null,
      dimension: {
        height: currentProduct.dimension.height
          ? Number(String(currentProduct.dimension.height).replace(/,/g, "."))
          : null,
        width: currentProduct.dimension.width
          ? Number(String(currentProduct.dimension.width).replace(/,/g, "."))
          : null,
        length: currentProduct.dimension.length
          ? Number(String(currentProduct.dimension.length).replace(/,/g, "."))
          : null,
        weight: currentProduct.dimension.weight
          ? Number(String(currentProduct.dimension.weight).replace(/,/g, "."))
          : null
      },
      product_datasheet: currentProduct.product_datasheet,
      product_related: currentProduct.product_related.map(e => e.product_id)
    }
    if (
      body.dimension.height !== null ||
      body.dimension.width !== null ||
      body.dimension.length !== null ||
      body.dimension.weight !== null
    ) {
      if (
        body.dimension.height + body.dimension.width + body.dimension.length >
        200.0
      ) {
        setAlertMessage("A soma das dimensões do produto deve ser menor que 200")
        setAlertType("error")
        setShowModalAlert(true)
        setLoading(false)
        return
      }
      if (body.dimension.weight > 50.0) {
        setAlertMessage("Produto deve ter no máximo 50kg")
        setAlertType("error")
        setShowModalAlert(true)
        setLoading(false)
        return
      }
    }
    const productRequest = isEdit
      ? await produtosRequest.put(currentProduct.id, body)
      : await produtosRequest.create(body)
    if (productRequest.data.error === false) {
      setAlertMessage(
        isEdit ? "Produto alterado com sucesso!" : "Produto criado com sucesso!"
      )
      setAlertType("success")
      setShowModalAlert(true)
      setLoading(false)
      if (!isEdit) {
        setCurrentProduct((prev) => ({
          ...prev,
          id: productRequest.data.content.id
        })
        )
        setIsEdit(true)
      }
    } else {
      setAlertMessage(
        isEdit ? "Erro ao editar o produto!" : "Erro ao criar o produto!"
      )
      setAlertType("error")
      setShowModalAlert(true)
      setLoading(false)
    }
    setLoading(false)
  }

  /* EDIT PRODUCT */
  const handleEditProduct = async () => {
    setIsLoading(true)
    if (id !== undefined || currentProduct.id) {
      const selectedProduct = await produtosRequest.get(id == undefined ? currentProduct.id : id)
      setCategoriesListSelected(selectedProduct.data.data.categories)
      setCurrentProduct({
        ...selectedProduct.data.data,
        categories: selectedProduct.data.data.categories
          ? selectedProduct.data.data.categories.map(e => {
            return { id: e.category_id, name: e.name }
          })
          : [],
        show_product: selectedProduct.data.data.show_product !== 0 ? 1 : 0,
        dimension: {
          height: selectedProduct.data.data?.dimension?.height,
          width: selectedProduct.data.data?.dimension?.width,
          length: selectedProduct.data.data?.dimension?.length,
          weight: selectedProduct.data.data?.dimension?.weight
        }
      })
    }
    setLoading(false)
    setIsLoading(false)
  }

  /* HANDLE LEAVING */
  const handleLeaving = () => {
    if (
      currentProduct &&
      (currentProduct.title.length > 0 ||
        currentProduct.price.length > 0 ||
        currentProduct.images.length !== 0)
    ) {
      setLeaving(true)
    } else {
      setTabNumber(1)
      history.goBack()
    }
  }

  const handleCloseProduct = () => {
    setTabNumber(1)
    history.goBack()
  }

  /* CHECK REQUIRED INPUTS */
  function checkRequiredInputs() {
    let errors = [0, 0, 0]
    if (currentProduct) {
      if (!!currentProduct.title === false) errors[0] = 1
      if (!!Number(currentProduct.price) === false) errors[1] = 1
      if (currentProduct.images.length === 0) errors[2] = 1

      setEmptyRequired({
        title: errors[0] === 1,
        price: errors[1] === 1,
        images: errors[2] === 1
      })
      if (errors[0] || errors[1] || errors[2]) {
        if (errors[0] && errors[1] && errors[2]) {
          setAlertMessage("Preencha os campos obrigatórios.")
        } else if (errors[0]) {
          setAlertMessage("Preencha o título do produto.")
        } else if (errors[1]) {
          setAlertMessage("Preencha o preço do produto.")
        } else if (errors[2]) {
          setAlertMessage("Preencha a imagem do produto.")
        }
        setAlertType("error")
        setShowModalAlert(true)
      }
      return !!errors.find(el => el === 1)
    }

    return !!errors.find(el => el === 1)
  }

  /* ORDER IMAGES BEFORE SAVE */
  function orderImages(fotosArray) {
    let orderedImagesArray = []
    if (isEdit && fotosArray) {
      orderedImagesArray = fotosArray.map((image, index) => {
        image.order = index + 1
        image.dndID = index + 1
        return image
      })
      return orderedImagesArray
    }
    orderedImagesArray = currentProduct.images.map((image, index) => {
      image.order = index + 1
      image.dndID = index + 1
      return image
    })
    return orderedImagesArray
  }

  const handleOnScroll = () => {
    if (listInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current

      if (Math.floor(scrollTop + clientHeight) === scrollHeight) {
        setIsScroll(true)
        setTimeout(() => {
          setIsScroll(false)
        }, 1000)
      }
    }
  }

  return (
    <>
      <div className="page-container">
        <div className="page-align">
          <div className={classes.header}>
            <TopProdutos
              name={isEdit ? "Editar Produto" : "Novo Produto"}
              handleBack={handleLeaving}
              handleDelete={handleDelete}
              isEdit={isEdit}
            />
            <p className={`${classes.productLabel} title`}>
              {currentProduct && currentProduct.title ? currentProduct.title : ""}
            </p>
            <Tabs
              value={tabNumber}
              onChange={(e, value) => setTabNumber(value)}
              indicatorColor="secondary"
              textColor="secondary"
              variant="scrollable"
              scrollButtons="auto"
              className={classes.productTabPanel}
            >
              <Tab
                label={
                  emptyRequired.title === true || emptyRequired.price === true ? (
                    <span>Informações ●</span>
                  ) : (
                    <span>Informações</span>
                  )
                }
                value={1}
                icon={
                  <BsFileEarmarkText
                    style={{ fontSize: "1.2rem", margin: "0 5px" }}
                  />
                }
              />
              <Tab
                label={
                  emptyRequired.images === true ? (
                    <span>Fotos ●</span>
                  ) : (
                    <span>Fotos</span>
                  )
                }
                value={2}
                icon={<BsCamera style={{ fontSize: "1.2rem", margin: "0 5px" }} />}
              />
              <Tab
                label="Estoque"
                value={3}
                icon={<BsBoxSeam style={{ fontSize: "1.2rem", margin: "0 5px" }} />}
              />
              <Tab
                label="Variações"
                value={4}
                icon={
                  <MdOutlineDiscount
                    style={{
                      fontSize: "1.2rem",
                      margin: "0 5px"
                    }}
                  />
                }
              />

              <Tab
                label="Dimensões"
                value={5}
                icon={
                  <RiRulerLine
                    style={{
                      fontSize: "1.2rem",
                      margin: "0 5px"
                    }}
                  />
                }
              />
              <Tab
                label="Ficha técnica"
                value={6}
                icon={
                  <MdOutlineDesignServices
                    style={{
                      fontSize: "1.2rem",
                      margin: "0 5px"
                    }}
                  />
                }
              />
              <Tab
                label="Produtos relacionados"
                value={7}
                icon={
                  <MdPlaylistAdd
                    style={{
                      fontSize: "1.2rem",
                      margin: "0 5px"
                    }}
                  />
                }
              />
            </Tabs>
          </div>
          <div
            className="infinite-scroll"
            onScroll={() => handleOnScroll()}
            ref={listInnerRef}
          >
            {tabNumber === 1 && <ProdutoInformacoes emptyRequired={emptyRequired} />}
            {tabNumber === 2 && <ProdutoFotos />}
            {tabNumber === 3 && <ProdutoEstoque />}
            {tabNumber === 4 && <ProdutoVariacoes onScroll={isScroll} />}
            {tabNumber === 5 && <Dimensoes />}
            {tabNumber === 6 && <FichaTecnica />}
            {tabNumber === 7 && <ProdutosRelacionados />}
          </div>
          <div className="footer-column">
            <hr style={{ width: "100%", margin: "20px 0" }} />
            <Dialog className={classes.modalLeaving} open={leaving || leavingNoSave}>
              <div>
                <h2>
                  {leaving
                    ? "Salvar alterações do produto antes de sair?"
                    : "Erro ao salvar produto!"}
                </h2>
                {leaving ? (
                  <p>Existem informações desse produto que ainda não foram salvas</p>
                ) : (
                  <p>
                    Para o checkout automático funcionar você precisa configurar as
                    dimensões do produto.
                    <br />
                    <span style={{ color: "red" }}>
                      {" "}
                      Se você sair seu produto não será salvo!{" "}
                    </span>
                  </p>
                )}
              </div>
              <Button
                className={classes.btnFooterPink}
                disabled={loading}
                onClick={() => {
                  setLeaving(false)
                  setLeavingNoSave(false)
                }}
                fullWidth
                variant="contained"
                color="secondary"
              >
                continuar editando
              </Button>
              <Button
                className={classes.btnFooterWhite}
                fullWidth
                variant="outlined"
                color="secondary"
                onClick={() => handleCloseProduct()}
              >
                sair sem salvar
              </Button>
            </Dialog>

            <Button
              className={classes.btnFooterPink}
              disabled={loading}
              onClick={() => saveProduct()}
              fullWidth
              variant="contained"
              color="secondary"
            >
              Salvar Produto
            </Button>
          </div>
        </div>
      </div>
      {/* ALERT COMPONENT */}
      {showModalAlert ? (
        <CustomAlert
          type={alertType}
          alertText={alertMessage}
          show={showModalAlert}
          close={() => setShowModalAlert(false)}
        />
      ) : (
        ""
      )}
      {/* MODAL DELETE PRODUCT */}
      {openModalDelete ? (
        <ModalExcluirProduto
          isOpen={handleDelete}
          idProduct={currentProduct.id ? currentProduct.id : null}
          handleClose={handleCloseModal}
        />
      ) : (
        ""
      )}
    </>
  )
}
